<template>
    <div id="app"> 
      <router-view />
    </div>
  </template>

<script>
import { reactive, toRefs } from "vue";

export default {
    name: "App",
    setup() {
        const state = reactive({
            navData: { title: undefined, back: false },
        });
        return {
            ...toRefs(state),
        };
    },
    watch: {
        //使用watch 监听$router的变化
        $route(to, from) {
            //如果to索引大于from索引,判断为前进状态,反之则为后退状态
            this.navData = to.meta;
        },
    },
};
</script>

<style lang="scss">
#app {
    width: 100%;
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.appHasNav {
    padding-top: 0.88rem;
}
</style>
