
let mutations = {
    SET_TOKEN: (state, data) => {
        state.token = data.token
        state.userInfo = data
      },
    SET_PARNTER: (state,data) => {
      state.userInfo = data
    }
};

export default mutations
