import axios from 'axios'  
import router from '@/router'
import {
    getToken, 
    removeToken, 
} from '@/utils/auth'
import { Notify } from 'vant';
// 创建axios实例
const service = axios.create({
    // baseURL: "http://192.168.2.5:8081",
    // baseURL: 'https://a123api.abcde999.com',
    baseURL: "https://nsapi.uunse.com",
    // baseURL: 'http://192.168.2.5:8088',
    // withCredentials: true, // 跨域请求时发送Cookie
    timeout: 60000 // 请求超时
})
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 发送之前 携带token
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded' 
        config.headers['source'] = 1
        config.headers['token'] = getToken()
        // config.headers['isrefreshtoken'] = getRefToken()
        return config
    },
    error => {
        // 请求错误
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(

    response => {
        const res = response.data 
        // setRefToken(1)
        // 如果状态码不是2000，则判断为错误。
        if (res.code !== 2000) {
            // Message({
            //   message: res || 'Error',
            //   type: 'error',
            //   duration: 5 * 1000
            // })
            // if (res.code === 4802) {
            //     // 登录失败需要输入2fa验证码 
            //     return Promise.reject("need2FA");
            // } 
            // 401: token不存在或者失效; 
            Notify({ type: 'warning', message: res.msg });
            if (res.code === 2008) {
                // setOverdue(0)
                // to re-login

                removeToken()
                router.push({
                    name: "login"
                });
            }

            return Promise.reject(res)
        } else {
            // setOverdue(1);
            return res.data
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service