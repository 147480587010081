import request from '@/utils/request.js';

/// 登录
export function loginPost(data) {
    return request({
        url: '/api/auth/partner/login',
        method: 'post',
        data
    })
}

// 退出登录
export function loginOutRequest() {
    return request({
        url: 'api/auth/user/logout',
        method: 'post',
    })
}

/// 获取合伙人信息
export function getPartnerInfo() {
    return request({
        url: '/api/user/partner/info',
        method: 'get',
    })
}

/// 获取合伙人月统计信息
export function getPartnerMonth(data) {
    return request({
        url: '/api/user/partner/month',
        method: 'get',
        params: data
    })
}

/// 合伙人好友月统计信息
export function getPartnerFriendMonth(data) {
    return request({
        url: '/api/user/partner/friend/month',
        method: 'get',
        params: data
    })
}

/// 查询合伙人好友列表包含统计
export function getPartnerFriendList(data) {
    return request({
        url: '/api/user/partner/friend/list',
        method: 'get',
        params: data
    })
}

/// 获取广告弹窗
export function getGlobalAdvertiseList() {
    return request({
        url: '/api/currency/adv/lists',
        method: 'get',
        params: { "adv_flg": "Global_P" },
    })
}