import { createStore } from 'vuex';
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import modules from "./modules";
import {
  getToken
} from '@/utils/auth'

const state = { 
  token: getToken() ? getToken() : false,
  userInfo: undefined,
  parnterInfo: undefined,
};
export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules
})
