import { createApp } from 'vue';
import Router, { createRouter, createWebHistory } from 'vue-router'
import { Toast } from 'vant';
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条 样式

import store from "../store/index";
import { getToken } from "../utils/auth.js"

const routes = [
  // {
  //   path: "/",
  //   name: "IndexPage",
  //   redirect: "/index/data",
  //   component: () => import("@/views/IndexPage.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/index",
    name: "IndexPage",
    redirect: "/index/data",
    component: () => import("@/views/IndexPage.vue"),
    children: [
      {
        path: "/index/data",
        name: "DataPage",
        component: () => import("@/views/data/index.vue")
        // component: () => import("@/views/Login.vue")
      },
      {
        path: "/index/member",
        name: "Member",
        component: () => import("@/views/Member.vue")
        // component: () => import("@/views/Login.vue")
      },
      {
        path: "/index/me",
        name: "Me",
        component: () => import("@/views/Me.vue")
        // component: () => import("@/views/Login.vue")
      },
      {
        path: "/index/data/details",
        name: "DataDetails",
        component: () => import("@/views/data/details.vue")
        // component: () => import("@/views/Login.vue")
      },
    ],
  },
  {
    path: '/:error*', // /:error -> 匹配 /, /one, /one/two, /one/two/three, 等
    redirect: "/index/data",
    component: () => import("@/views/IndexPage.vue"),
  } 
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  // 配置激活时要使用的类名
  // linkActiveClass: 'router-link-active',
  routes
});

NProgress.configure({
  showSpinner: false
});

// 路由守卫
router.beforeEach((to, from, next) => {
  NProgress.start(); // 开启Progress
  if (getToken()) { // 通过vuex state获取当前的token是否存在
    next();
  } else if (to.path == '/login') {
    next();
  } else {
    next("/login")
  }
});

router.afterEach((to, from) => {
  // Toast.clear();
  NProgress.done(); // 结束Progress
});


export default router;
