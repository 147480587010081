import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/index.js';
import 'default-passive-events';
import 'amfe-flexible';
// 导入初始化样式表
import "@/assets/css/reset.css";
import { Field, button, overlay, PasswordInput, List, NumberKeyboard, Tabbar, TabbarItem, icon, Notify ,Loading } from 'vant';
// import vant from 'vant';

const app = createApp(App);

app.use(store)
app.use(router)
app.use(Field)
app.use(button)
app.use(overlay)
app.use(icon)
app.use(PasswordInput)
app.use(NumberKeyboard)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Notify)
app.use(List)
app.use(Loading)
app.mount('#app');
