
let getters = {
    // userinfo:(state)=>{
    //     return state.userinfo
    // }
};

export default getters


