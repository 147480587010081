
import { getPartnerInfo } from "@/api/api.js"
let actions = {
    /// 获取合伙人信息
    async getParnterInfo(content) {
        let _result = await getPartnerInfo()
        content.commit("SET_PARNTER",_result)

    }
};

export default actions

