
// token
const TokenKey = 'ns-partner-token' 

export function getToken() {
  return localStorage.getItem(TokenKey)
      // return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  // return sessionStorage.setItem(TokenKey, token)
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  // return sessionStorage.removeItem(TokenKey)
  return localStorage.removeItem(TokenKey)
}
 